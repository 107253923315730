@import url(../../styles/variables);

.inputHolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.listnumborder {
  margin-right: -2px;
  z-index: 9000;
  border: 1px solid #0e1f35;
  background-color: #0e1f35;
  border-radius: 5px 0 0 5px;
  width: 54px;
  height: 54px;
  color: white;
  /* text-align: center; */
  display: flex;
  font-size: large;
  justify-content: center;
  align-items: center;
  align-content: center;
}
