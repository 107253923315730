@import '../../styles/appvar';

.uploaderHolder {
  background: #FAFAFA;
  position: relative;
  height: 191px;
  margin-top: 20px;
  width: 100%;
  max-width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 29px;
  color: #FAFAFA;
  border: 2px dashed #E0DFDF;
}

.uploadInfoText {
  font-size: 17px;
  text-align: center;
  color: #202124;
  width: 110px;
  line-height: 20px;
  letter-spacing: 0.17px;
  font-weight: bold;
  margin-top: 3px;
  margin-bottom: 3px;
}

.uploadIcon {
  text-align: center;
  color: #707070;
  font-size: 35px;
}

.fileTypeText {
  font-size: 14px;
  text-align: center;
  color: #9999A3;
  width: 270px;
  line-height: 20px;
  letter-spacing: 0.14px;
  margin-bottom: 3px;
}

.btnBrowse {
  //height: 20px;
}

.fileHolder {
  background: #FAFAFA;
  height: 48px;
  width: 100%;
  max-width: 313px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 11.36px;
  padding-right: 11.36px;
  justify-content: space-between;
  margin-bottom: 8px;
  border: 1px solid var(--unnamed-color-eeeded);

  @media (max-width: @screen-xl) {
    width: 320px;
  }
  @media (max-width: @screen-xl) {
    width: 320px;
  }

  @media (max-width: @screen-md) {
    width: 36vw;
  }
  @media (max-width: @screen-md) {
    width: unset;
  }

}

.rowContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.fileIcon {
  text-align: center;
  color: #707070;
  font-size: 25px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 9px;
}

.fileName {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.cloudIcon {
  text-align: center;
  color: #04914D;
  font-size: 20px;
}

.closeIcon {
  text-align: center;
  color: #000;
  font-size: 20px;
}

.subText {
  text-align: left;
  letter-spacing: 0.12px;
  color: #9999A3;
  opacity: 1;
  font-size: 12px;
}

.titleText {
  text-align: left;
  letter-spacing: 0.14px;
  color: #202124;
  opacity: 1;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.errorText {
  font-size: 16px;
  color: red;
  margin-top: 5px;
}
