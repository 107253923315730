@import url(../../styles/variables);

.cardHolder {
  width: 100%;
  max-width: 702px;
  padding: 15px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  opacity: 1;
  margin-top: 20px;
  cursor: pointer;
}

.checkIcon {
  color: @primary-color;
}

.formButtons {
  padding: 12px 32px;
  height: 43px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
