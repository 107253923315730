.c360 .ant-form-item-label > label {
  font-weight: 600;
  line-height: 1;
}

.c360 .ant-form-item-label {
  padding-bottom: 2px !important;
  line-height: 1;
  font-size: 14px;
}

.c360 > .ant-select-selector {
  background-color: #ECEFF1 !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px 0 0 8px !important;
  height: 65px !important;
  align-items: center !important;
}


.c360 > .ant-steps-item > .ant-steps-item-container {
  display: flex !important;
  align-items: center !important;
}

.c360 .ant-table-cell {
  padding: 8px 20px !important;
}

.c360 .ant-table-pagination {
  align-items: center;
}

.c360 .ant-table-expanded-row-fixed {
  margin: -8px -20px !important;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: none;
  opacity: 0.2;
}


*::-webkit-scrollbar-thumb {
  background: none;
  opacity: 0.8;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #aaa;
  width: 16px;
}

*:hover::-webkit-scrollbar-thumb {
  background: #bbb;
}

.no-horizontal-scrollbar *::-webkit-scrollbar-thumb:horizontal {
  background: #888;
  border-radius: 20px;
  opacity: 0.8;
  display: none;
}


.no-horizontal-scrollbar *::-webkit-scrollbar:horizontal {
  display: none;
}

.ant-pagination {
  padding: 0 20px;
}

.ant-pagination-prev, .ant-pagination-next {
  display: none !important;
}

.ant-pagination-item-active:hover{
  background-color: #009E52;
}

.ant-pagination-item {
  border: none !important;
  border-radius: 50% !important;
  min-width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
}
.ant-pagination-item:hover {
  background-color: unset;
  color: #009E52;
}

.ant-pagination-item-active {
  background-color: #009E52 !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-pagination.mini > .ant-pagination-item {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
}
.ant-btn > *{
  font-weight: 500;
}
.ant-btn-primary{
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
}

.ant-modal-confirm-btns > .ant-btn-primary {
  background-color: #009E52;
  height: 54px;
  font-size: 16px;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
}
.ant-modal-confirm-btns > .ant-btn-primary:hover {
  background-color: #009E52;
}
.ant-modal-confirm-btns > .ant-btn-default {
  height: 54px;
  font-size: 16px;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
}
.ant-modal-confirm-btns > .ant-btn-default:hover {
  color: #009E52;
  border-color: #009E52;
}
.ant-modal-confirm-btns > .ant-btn-dangerous{
  background-color: #FC646A !important;
}
.ant-modal-confirm-title{
  font-size: 20px !important;
  font-family: Roboto, sans-serif;
  font-weight: 500 !important;
}
.ant-modal-confirm-content{
  font-size: 16px !important;
  font-family: Roboto, sans-serif;
}
.ant-notification-notice-message{
  font-size: 20px !important;
  font-family: Roboto, sans-serif;
}
.ant-notification-notice-description{
  font-size: 16px !important;
  font-family: Roboto, sans-serif;
}
.ant-radio {
  margin-top: 0 !important;
}
.slick-dots>li>button::after{
background-color: rgba(0,0,0,.1607843137254902)!important;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.slick-dots{
  gap: 4px;
}
.slick-dots>li{
  width: 16px;
  height: 16px;
}
.slick-active{
  margin-right: -4px !important;
}

.ant-pagination-options-size-changer>.ant-select-selector {
  padding: 0 11px !important;
}
