.templateEditor {
  position: relative;
  min-height: 100px;
  height: auto;
}

.templateEditor .templateEditorOverlay {
  /*background-color: red;*/
  position: absolute !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.emailEditor {
  width: 100%;
  min-height: calc(100vh - 300px);
}

.templateEditorDisplay {
  position: relative;
  width: 100%;
}
