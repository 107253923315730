.richText {
  display: flex;
  flex-direction: column;
  justify-content: right;
}

.richTextCount {
  width: 100%;
  text-align: right;
  padding: 4px;
  margin-bottom: -26px
}

.c360 {
  :global {
    .ql-toolbar {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .ql-container {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .ant-form-item-has-error {
      .ql-toolbar, .ql-container {
        border-color: red;
      }

    }
  }
}

