@PREFIX: img-crop;

.@{PREFIX}-modal {
  .@{PREFIX}-container {
    position: relative;
    width: 100%;
    height: 40vh;
  }

  .@{PREFIX}-control {
    display: flex;
    align-items: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
      margin-top: 16px;
    }

    &:last-of-type {
      margin-bottom: -8px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      padding: 0;
      font-style: normal;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;

      &[disabled] {
        cursor: default;
      }
    }

    button + div:only-of-type {
      flex: 1;
      margin: 0 8px;
    }
  }

  .@{PREFIX}-control-zoom button {
    font-size: 18px;
  }

  .@{PREFIX}-control-rotate button {
    font-size: 16px;

    &:first-of-type {
      transform: rotate(-20deg);
    }

    &:last-of-type {
      transform: rotate(20deg);
    }
  }
}
