@import url(../../styles/variables);

.inputHolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.verifyBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.phoneInput {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  justify-content: stretch;
}

.phoneInputHolder {
  flex-grow: 1;
  position: relative;
}

.eyeBtn {
  padding-right: 10px;
  padding-left: 10px;
}

.verifiedText {
  color: @primary-color;
}

.phoneInputEye {
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 10px;
}

.phoneInputWithHide {
  position: relative;
}

.phoneInputClose {
  position: absolute;
  right: 54px;
  top: 0;
  color: #ccc;
  padding-right: 10px;
  font-size: 12px !important;
}
