@import '../../styles/variables';

.phoneInput {
  position: relative;

  :global {
    .PhoneInput {
      height: @height-base;
      width: 100%;

      .PhoneInputInput {
        box-sizing: border-box;
        margin: 0;
        height: @height-base;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 4px 11px 4px 60px;
        color: @text-color;
        font-size: @font-size-base;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 1px solid @border-color-base;
        border-radius: @border-radius-base;
        transition: all 0.3s;
        margin-left: -43px;
      }

      .PhoneInputInput:focus {
        border-color: @primary-color;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(3, 73, 104, 0.2);
      }

      .PhoneInputCountrySelect {
        z-index: 2;
      }

      .PhoneInputCountryIcon {
        width: 28px;
        height: 20px;
        z-index: 1;
        transform: translateX(18px);
      }

      .PhoneInputCountryIcon--border {
        border: none;
      }
    }

    .ant-form-item-has-error {
      .PhoneInputInput {
        border: 1px solid @error-color;
      }
    }
  }
}

.phoneVerifyInput {
  box-sizing: border-box;
  margin: 0;
  height: @height-base;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 0 17px;
  color: @text-color;
  font-size: @font-size-base;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  //border: 1px solid @border-color-base;
  //border-radius: @border-radius-base;
   border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.phoneVerifyInputText {

}

.phoneVerifyInputPlaceholder {
  color: #bbb;
}


.disabled {
  :global {
    .PhoneInputInput {
      background-color: #F5F5F5 !important;
      cursor: not-allowed;
    }
  }
}