@import '../../styles/appvar';

.salary {
  :global {
    .ant-input-number {
      width: 100%;
      padding-left: 46px;
      position: relative;

      .ant-input-number-input {
        //margin-top: 10px;
      }

      :after {
        content: "LKR";
        position: absolute;
        left: 20px;
        top: 14px;
        color: @lite-color;
      }
    }
  }

}

.salaryRange {
  width: 100%;
}

.toSalaryRange {
  width: 70%;
}
