@import url(../../../styles/variables.less);

.actionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  justify-content: flex-end;
}

.serchBtn {
  max-width: 556px;
  margin-right: 20px;
}

.unReadApplication {
  background-color: #f1fff8;
}

.greenDot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: -20px;
  background-color: #1bab61;
  transform: translate(-45px, -18px);
}

.unreadContainer {
  margin-left: -8px;
}

.serchBtn > span > span {
  height: 52px;
  padding: 0 17px;
}

.tableHolder {
  margin-top: 20px;

  :global {
    .ant-table-row-selected {
      > td {
        background: #c2dac9 !important;
      }
    }

    .ant-table-row-hover {
      border-bottom: solid 1px #272727;
      border-top: solid 1px #272727;
    }
  }
}

.feedbackFormTable,
.emailTemplateTable {
  :global {
    a {
      color: @primary-color;
      font-size: 16px;
    }

    a:hover {
      color: @primary-color;
    }
  }
}

.tableHeader {
  background-color: #ffffff;
}

.applicationtable,
.headHuntTable {
  th:nth-child(2) {
    min-width: 320px !important;
  }
}

.pA16 {
  padding: 0 16px 16px 16px;
}

.actionHolder {
  display: flex;
  //position: absolute;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;

  > * {
    margin-left: 10px;
    cursor: pointer;
  }

  > *:first-child {
    margin-left: unset;
  }
}

.actionApplication {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  justify-content: space-between;
  width: 100%;
}

.rowApplication {
  align-items: flex-start;
}

.layoutContent {
  color: red;
  margin-top: 17px;
  line-height: 28px;
}

.columnHide {
  display: none;
}

.columnShow {
  //display: flex;
}

.c360 {
  :global {
    .schema-form-item {
      margin-bottom: 30px;
    }
  }
}

.flagIcon {
  color: #eb851d;
  //margin-left: -30px;
  width: 20px;
  transform: translate(-56px, 25px);
}

.tableRowLight {
  background-color: #ffffff;
}

.tableRowDark {
  background-color: #00ffff;
}

.flagLink {
  margin-left: -25px;
}

.rolesButton {
  margin: 0 20px;
}

.roleColumn {
  font-weight: bold;
}

.tableRowLight {
  background-color: #ffffff;
}

.tableRowDark {
  background-color: #f5f5f5c2;
}

.roleTable {
  :global {
    .ant-table {
      .ant-table-content {
        overflow-x: hidden !important;
      }
    }
  }

  :global {
    thead[class*='ant-table-thead'] th {
      background-color: #f5f5f5c2 !important;
      text-align: center;
      font-weight: bolder;
      // min-width: 190px;
    }
  }
}

.roleModal {
  :global {
    .ant-modal-header {
      background-color: #d9d9d9;
    }

    .ant-modal-close-x {
      line-height: 0;
      padding: 20px 25px 20px 0;
    }
  }

  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: red;
  }
}
