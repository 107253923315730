@import '../../../styles/appvar';

.searchPanel {
  width: 100%;
  display: flex;
  justify-content: center;
  //padding-bottom: @padding-md;
}

.searchContentArea {
  //height: 100px;
  max-width: @candidate-width;
  width: 100%;
  padding: 11px 65px 12px 65px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: @padding-sm;

  @media (max-width: @screen-lg-max) {
    padding: 0 16px 12px 16px;
  }
  @media (max-width: @screen-sm-max) {
    padding: 0 0 12px 0;
  }
}

.searchInputBar {
  display: flex;
}

.searchInputBarSelect {
  min-width: 200px;
  max-width: 200px;
  text-align: center;
  flex-grow: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;

  :global {
    .ant-select-selector {
      padding-top: 1.5px;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
      background-color: @light-gray !important;

      .ant-select-selection-item {
        justify-content: center;
      }
    }
  }

  @media (max-width: @screen-md-max) {
    min-width: 120px;
    max-width: 120px;
  }
}

.searchInputBarSearch {
  flex-grow: 1;

  :global {
    .ant-input {
      background-color: @light-gray !important;
      min-height: unset !important;
    }

    .ant-input-affix-wrapper {
      background-color: @light-gray !important;
      @media (min-width: @screen-md) {
        border-radius: 0 !important;
      }
    }
  }
}

.searchInputBarButton {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.searchFilterBar {
  display: flex;
  gap: 3px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
  height: auto;
}

.searchFilterContainer {
  display: flex;
  gap: @app-padding-xs;
  height: auto;
  align-items: center;
  // margin-top: 5px;
}

.arrowicon {
  flex-grow: 0;
  flex-shrink: 0;
  //@media (min-width: 1400px) {
  //  display: none;
  //}
}

.searchLayout {
  min-height: calc(100vh - 202px);
  display: flex;
  align-items: flex-end;
  @media (max-width: @screen-md-max) {
    align-items: flex-start;
  }
}

.searchLayoutWithBanner {
  min-height: calc(100vh - 252px);
}

.searchLayoutNoJob {
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.searchResultListHolder {
  padding-left: @app-padding-lg;
  padding-top: @app-padding-lg;
  padding-bottom: @app-padding-lg;
}

.searchResultListContainer {
  flex-grow: 0;
  width: 550px;
  padding-left: @app-padding-lg;
  padding-top: @app-padding-lg;
  padding-bottom: @app-padding-lg;
  border-right: 1px solid @light-gray;
  border-bottom: 1px solid @light-gray;
  min-height: calc(100vh - 202px);

  @media (min-width: @screen-lg) and (max-width: @screen-lg-max) {
    width: 45vw;
  }

  @media (max-width: @screen-md-max) {
    width: 94vw;
    padding-left: unset;
    padding-right: unset;
    height: unset;
  }
  @media (max-width: @screen-sm-max) {
    margin-left: -20px;
    width: 100vw;
  }
}

.searchResultListContainerWithBanner {
  min-height: calc(100vh - 252px);
}

@media (max-width: @screen-md-max) {
  .jobCountInfo {
    padding: 0 @app-padding-lg;
  }
}

.searchResultContainer {
  min-height: calc(100vh - 202px);
  padding: @app-gap-lg;
  flex-grow: 1;
  height: calc(100vh - 202px);
  overflow-y: auto;

  @media (max-width: @screen-md-max) {
    padding: unset;
    min-height: unset;
    height: unset !important;
  }
}

.searchResultContainerWithBanner {
  min-height: calc(100vh - 252px);
  height: calc(100vh - 252px);
}

.SearchResultListItem {
  padding: @app-padding-lg !important;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s;
}

.SearchResultListItemSelected {
  background-color: #e5f7ff;
}

.turnOnJobAlerts {
  height: 22px;
  width: 44px !important;

  :global {
    .ant-switch-inner {
      width: 44px !important;
    }

    .ant-switch-handle {
      height: 18px;
      width: 18px;
    }
  }
}

.saved {
  color: @primary-color;
}

.boostIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fffdc4;
  color: #fd787d;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scaleX(-1);
  border: 1px solid #f7f5bc;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.jobDetailsCard {
  border: 1px solid @light-gray;
  border-radius: 10px;
  padding: @app-gap-xl;
  width: 100%;
  //max-width: 696px;
  overflow: hidden;

  :global {
    .ant-flex-item {
      max-width: 656px !important;
    }
  }

  @media (max-width: @screen-md-max) {
    border: none;
    padding: @app-gap-lg;
  }
}

.pText {
  text-align: left;
  line-height: 2;
}

.pagination {
  padding: 0 @app-padding-lg;

  :global {
    .ant-pagination-prev,
    .ant-pagination-next {
      display: none;
    }

    .ant-pagination-item {
      border: none;
      border-radius: 50%;
    }

    .ant-pagination-item-active {
      background-color: @primary-color;
    }

    .ant-pagination-item-active a {
      color: white !important;
    }
  }
}

.jobImageViewHolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.jobImageView {
  width: 100%;
  border: 1px solid @light-gray;
}

.jobImageContainer {
  width: 100%;
  overflow-y: auto;
  max-height: 400px;
}

.jobImage {
  width: 100%;
}

.jobImageActions {
  border-top: 1px solid @light-gray;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: @app-gap-lg;
  background: #f6f6f6 0 0 no-repeat padding-box;
}

.jobThumbnail {
  max-height: 60px;
  width: auto;
  background-color: white;
  box-shadow: 0 3px 6px #00000031;
  border: 1.5px solid @primary-color;
}

.resultDrawerTitle {
  display: flex;
  justify-content: center;
  width: calc(100vw - 150px);
}

.serchTypeTabs {
  margin-top: -20px;

  :global {
    .ant-tabs-tab {
      padding: @padding-xs @padding-lg;
      width: calc(50vw - 20px);
      margin-left: 0 !important;
      justify-content: center;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}

.viewAllJobs {
  min-width: 72px;
  white-space: pre-wrap;
  justify-content: center;
  margin-top: -8px;
  @media (min-width: @screen-md) {
    padding: 0;
    max-width: 72px;
  }
}

.viewAllJobspopup {
  min-width: 72px;
  white-space: pre-wrap;
  justify-content: center;
  color: #4b4d4d;
  margin-top: -8px !important;
  font-size: 14px;
  @media (min-width: @screen-md) {
    padding: 0;
    max-width: 72px;
  }
}

.viewRecruiter {
  padding: 0;
  width: 72px;
  height: 72px;
}

.noJobAlertContainer {
  display: flex;
  flex-direction: column;
}

.modalpadding {
  padding-left: 10.5%;
  padding-right: 10.5%;
  padding-top: 30px;
}

.modalpaddingb {
  padding-left: 10.5%;
  padding-right: 10.5%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.deviderpadding {
  padding-left: 10.5%;
  padding-right: 10.5%;

  // padding-bottom:40px;
  color: #6c6c6c;
  font-size: 16px;
  border-color: #7cb305;
  border-width: 2px;
}

.buttonpadding {
  padding-left: 20.5%;
  padding-right: 20.5%;
  @media (max-width: @screen-sm-max) {
    padding-left: 0;
    padding-right: 0;
  }
}

.modalclose {
  :global {
    .ant-modal-close {
      top: 12px;
      right: 20px;
    }

    .ant-modal-close-x {
      color: #fd787d;
    }

    .ant-modal-close:hover {
      background-color: white;
    }

    .ant-modal-close-icon {
      border: 1px solid #fd787d;
      border-radius: 50%;
      padding: 5px;
    }
  }
}

.button {
  border-radius: 10px;
}

.copybutton {
  :global {
    .ant-btn-icon-only.ant-btn-sm {
      width: 31px;
      height: 31px;
      min-width: 31px !important;
      padding: 4px 3px;
    }

    svg {
      width: 14px;
    }

    padding-bottom: 7px;
  }
}

.actionBtnContainer {
  gap: @app-padding-lg !important;

  @media (max-width: @screen-xs) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.actionButton {
  width: 130px;
  @media (max-width: @screen-xs) {
    flex-basis: 50%;
  }
  @media (max-width: 360px) {
    flex-basis: 50%;
    width: 120px;
  }
}

.eyeballMessage {
  background-color: #feeeee;
  border-left: solid 4px #fc646a;
  padding: @app-padding-lg @app-gap-md;
  border-radius: 5px;

  a {
    color: @primary-color;
    text-decoration: underline;
  }
}

.marginTopLg {
  margin-top: @app-padding-lg;
}

.jobAlertComponent {
  height: 30px;
  @media (max-width: @screen-lg) {
    margin-left: @app-padding-lg;
  }
}

.jobDrawerCloseIcon {
  color: @danger-color;
  font-size: 20px;
}

.footermobile {
  @media (min-width: 991px) {
    display: none;
  }
}

.featuredAdvert {
  position: absolute;
  right: 10px;
  bottom: 40px;
}

.featuredAdvertMobile {
  position: absolute;
  right: 20px;
  bottom: 10px;
  //for ipad
  @media (width: 834px) {
    right: 35px;
  }
  @media (width: 800px) {
    right: 35px;
  }
}

.featuredBadge_RefNo {
  left: 1031px;
  margin-top: 14px;
  //background-color: #f80000;
}

.jobCardTitle {
  margin-top: 0;
  font-size: 25px;
  margin-bottom: 12.5px;
}
