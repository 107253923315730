@import "variables";

.PhoneInput {
  height: 32px;
  input{
    border: solid 1px #d9d9d9 !important;
  }
  ::placeholder{
    color: #d2d2d2;
  }
  :focus{
    border: solid 1px #000000
  }

  .PhoneInputInput {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px 4px 51px;
    color: @text-color;
    font-size: @font-size-base;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border-radius: @border-radius-base;
    transition: all 0.3s;
    margin-left: -40px;
  }

  .PhoneInputInput:focus {
    border-color: @primary-color;
    //border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(3, 73, 104, 0.2);
    border: solid 1px @primary-color !important;
  }
  .PhoneInputInput:hover {
    border: solid 1px @primary-color !important;
  }
  .PhoneInputInput:active {
    border: solid 1px @primary-color !important;
  }
  .PhoneInputCountrySelect {
    z-index: 2;
  }

  .PhoneInputCountryIcon {
    width: 28px;
    height: 20px;
    z-index: 1;
    transform: translateX(7px);
  }

  .PhoneInputCountryIcon--border {
    border: none;
  }
}

.schema-form-array-remove {
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  padding: 0 !important;
  position: absolute !important;
  right: -10px !important;
  top: -16px !important;
  border-radius: 10px !important;
  color: red !important;
  font-size: 24px !important;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease-in;

  :hover {
    box-shadow: 0 0 4px red;
    border-radius: 15px;
  }
}
