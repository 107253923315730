@import "../../../styles/appvar";

.contentBlock {
  display: flex;
  line-height: 1.5;
}

.textBlock {
  line-height: 1.5;
}

.textHeading {
  .textBlock;
  font-family: 'Roboto', serif;
}

.contentBlockFix {
  flex-grow: 0;
  flex-shrink: 0;
}

.contentBlockContent {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentBlockTitle {
  color: @text-color;
  font-weight: bold;
}

.contentBlockStrong {
  font-weight: bold;
}

.contentBlockEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.contentBlockVertical {
  flex-direction: column;
}

.contentBlockPrimary {
  color: @primary-color;
}

.contentBlockDanger {
  color: @error-color;
}

.contentBlockNormal {
  color: @text-color;
}

.contentBlockSecondary {
  color: @app-text-color-secondary;
}

.contentBlockGapSm {
  gap: @app-gap-sm;
}

.contentBlockGapMd {
  gap: @app-gap-md;
}

.contentBlockGapLg {
  gap: @app-gap-lg;
}

.contentBlockGapXl {
  gap: @app-gap-xl;
}

.contentBlockTitleLevel1 {
  font-size: @app-font-size-title1;
}

.contentBlockTitleLevel2 {
  font-size: @app-font-size-title2;
}

.contentBlockTitleLevel3 {
  font-size: @app-font-size-title3;
}

.contentBlockTitleLevel4 {
  font-size: @app-font-size-title4;
}

.contentBlockTitleLevel5 {
  font-size: @app-font-size-title5;
}

.contentBlockTitleLevel6 {
  font-size: @app-font-size-title6;
}

.contentBlockTitleLevel7 {
  font-size: @app-font-size-title7;
}

.contentBlockTextLevel1 {
  font-size: @app-font-size-text1;
}

.contentBlockTextLevel2 {
  font-size: @app-font-size-text2;
}

.contentBlockTextLevel3 {
  font-size: @app-font-size-text3;
}

.contentBlockTextLevel4 {
  font-size: @app-font-size-text4;
}

.contentBlockTextLevel5 {
  font-size: @app-font-size-text5;
}

.contentBlockTextLevel6 {
  font-size: @app-font-size-text6;
}

.contentBlockTextLevel7 {
  font-size: @app-font-size-text7;
}

.contentBlockTextLevel8 {
  font-size: @app-font-size-text7;
}

.contentBlockTextLevel9 {
  font-size: @app-font-size-text7;
}

