@import "../../../styles/appvar";

.Logo {
  border-radius: 10px;
  border: 1px solid @light-gray;
}

.LogoMedium {
  width: 72px;
  height: 72px;
}

.LogoSmall {
  width: 48px;
  height: 48px;
}

.LogoLarge {
  width: 96px;
  height: 96px;
}
