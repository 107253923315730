.data-table {
  tr {
    cursor: pointer;
  }

  tr:nth-child(odd) {
    background-color: #fafafa;
  }

  .ant-pagination-simple {
    li,
    li a {
      height: 40px !important;
      line-height: 40px !important;
      min-width: 60px;
    }
  }
}

.dt-std-row {
  width: 100%;
  display: flex;
  align-items: center;

  .dt-std-row-titles {
    flex-grow: 1;
  }

  .dt-std-row-avatar {
    padding-right: 8px;
  }

  .dt-std-row-subtitle,
  .dt-std-row-sub-status {
    color: #aaa;
    font-size: 0.9em;
    padding-top: 4px;
  }
}

@media (max-width: 767px) {
  .data-table {
    border-top: 1px solid #eee;
    //margin-top: 8px;
  }
}
.ant-table-content {
  overflow-x: scroll !important;
}

.ant-table-content::-webkit-scrollbar:horizontal {
  width: 10px;
  height: 13px;
}

.ant-table-content::-webkit-scrollbar-thumb:horizontal {
  background-color: rgb(206, 206, 206);
  border-radius: 5px;
  height: 70px;
}
