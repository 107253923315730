@import '../../styles/variables';

.dateFilterInput {
  box-sizing: border-box;
  margin: 0;
  height: @height-base;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 0 17px;
  color: @text-color;
  font-size: @font-size-base;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.dateFilterInput:hover {
  border: 1px solid @primary-color;
}

.dateFilterInput:active {
  border: 1px solid @primary-color;
}

.dateFilterInput:focus-visible {
  border: 1px solid @primary-color;
}
