.notification-list {
  .ant-dropdown-menu-items {
    padding-left: 0;

    .list-item-notification {
      padding-left: 24px;
      padding-right: 24px;

      .ant-divider-horizontal {
        margin: 0;
      }
    }
  }
}

.ant-dropdown-menu {
  max-height: 750px;
  overflow: auto;

  .ant-divider-horizontal {
    margin: 0;
  }
}
