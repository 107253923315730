@import '../../styles/appvar';

.mainContainer {
  width: 100%;
  justify-content: center;

  .contentContainer {
    //max-width: 1280px;
    display: flex;
    flex-direction: column;

    .topContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: @app-gap-lg;
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
  }
}

.buttonBar {
  width: 100%;

  :global {
    .ant-radio-button-wrapper {
      width: 25%;
      display: inline-flex;
      justify-content: center;
    }
  }
}

.tabText {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.sideContainer {
  flex-grow: 0;
  max-width: 412px;
  width: 50%;
  @media (max-width: 991px) {
    max-width: unset;
    width: 100%;
  }

  :global {
    .ant-space-item {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .sideProfileCard {
    background-color: #fafafa;
    border: solid 1px #ecedee;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: @app-padding-xl 0;
  }

  .profileAvatar {
    align-self: center;
    margin-bottom: 10px;
  }

  .profileCompletionValue {
    font-size: 20px;
    color: #04914d;
  }

  .refferalLeaderboard {
    background-color: #eceff1;
    width: 100% !important;
    border: solid 1px #f1f1f1;
    text-align: center;
    padding: @app-padding-md;
  }
}

.leaderboardItemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .leaderboardItem {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    border: solid 1px #f1f1f1;
    padding: 12px 20px;

    .leaderboardRank {
      border: solid 1px #e0dfdf;
      padding: 0 10px 0 10px;
      font-size: 20px;
      border-radius: 16px;
      margin-right: 10px;
      background-color: #ffffff;
    }

    //.leaderboardName{
    //  margin: 0 10px 0 10px;
    //  font-size: 20px;
    //}
    .leaderboardHeart {
      margin-top: 5px;
      color: #ff535a;
      font-size: 20px;
    }

    .leaderboardPoints {
      background-color: #04914d;
      padding: 6px 18px;
      color: #ffffff;
      border-radius: 30px;
    }
  }

  .leaderboardItemSelected {
    background-color: #e5f7ff;
  }

  .leaderboardItemButton {
    display: flex;
    flex-direction: column !important;

    .leaderboardItem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    justify-content: center;
  }
}

.leaderboardItemTxt {
  color: @primary-color;
  margin-top: 10px;
}

.popularSector {
  margin-top: 100px;
}

//.profileStrength{
//  display: flex;
//  justify-content: center;
//  margin: 40px 0 20px 0;
//
//  >div {
//    max-width: 265px;
//  }
//}
.jobCardButons:hover {
  > button {
    background-color: #050931;
    color: #ffffff;
    //border-color: #050931;
  }
}

.statusContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 27px;
  align-content: center;
  font-size: 14px;
  padding: 5px 10px 2px 10px;
  color: #ffffff;
  background-color: #222222;
  border-bottom-left-radius: 10px;
}

.buttonContainer {
  position: absolute;
  top: -30px;
  right: 0;
  z-index: 10;
}

.jobCardTitleContainer {
  width: 100%;

  :global {
    .ant-space-vertical {
      gap: 0 !important;
    }
  }
}

.jobCardTitle {
  font-size: 20px;
  font-weight: bold;
  color: #212121;
}

.jobCardCompany {
  font-size: 17px;
  color: #272727;
}

.jobCardAddress {
  font-size: 16px;
  color: #6c6c6c;
}

.shortlistCardContainer {
  display: flex;
  flex-direction: column;
  border: solid 1px #f1f1f1;
  padding: 20px;
  position: relative;

  .middleContainer {
    width: 100%;
  }

  //cursor: pointer;
}

.referBtn {
  width: 100%;
  max-width: 226px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.shortlistCardContainer:hover {
  background-color: #fafafa;
}

//.shortlistCardContainer:active {
//  background-color: #e5f7ff;
//}

.shortlistCardContainerUnread {
  background-color: #e5f7ff;
}

.shortlistCardContainerUnread:hover {
  background-color: #cceefd;
}

.shortlistCardDetailContainer {
  display: flex;
  flex-direction: row;
  position: relative;
}

.shortlistCardDetailLeft {
  flex-grow: 0;
  width: 170px;
  margin-right: 20px;

  > :first-child {
    margin-bottom: 20px;
  }
}

.shortlistCardDetailRight {
  flex-grow: 1;

  > :first-child {
    margin-bottom: 20px;
  }
}

.shortlistCardCompanyimage {
  padding: 5px;
  border-radius: 10px;
  border: solid 1px rgba(174, 184, 194, 0.49);
  width: 72px;
  height: 72px;
  margin-right: 20px;
}

.cardCloseButton {
  display: flex;
  position: absolute;
  top: 17px;
  right: 10px;
}

.red {
  color: #ff535a;
}

.notificationButton {
  width: 200px;
  align-self: center;
  justify-self: flex-end;
}

.notificationContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.btnWithdraw {
  width: 100px;
}

.btnWithdraw:hover {
  background-color: #fd787d;
  color: white !important;
}

.pagination {
  margin-top: 5px;
  padding: 0 @app-padding-lg;
  margin-bottom: 5px;

  :global {
    .ant-pagination-prev,
    .ant-pagination-next {
      display: none;
    }

    .ant-pagination-item {
      border: none;
      border-radius: 50%;
      min-width: 44px !important;
      height: 44px !important;
      line-height: 44px !important;
    }

    .ant-pagination-item-active {
      background-color: @primary-color;
    }

    .ant-pagination-item-active a {
      color: white !important;
    }
  }
}

.strengthText {
  max-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}

.leaderEmpty {
  height: 60px;
  border: solid 1px #f1f1f1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.leaderEmptyDot {
  width: 7px;
  height: 7px;
  background: #d2d2d2 0 0 no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  margin: 2px;
}

.column {
  display: flex;
  flex-direction: column;
}

.appliedDateIcon {
  color: @primary-color;
}

.contentLeft {
  min-width: 200px;
}

.contentLeftAlert {
  min-width: 80px;
}

.contentCenterAlert {
  min-width: 110px;
}

.gap8 {
  gap: @app-padding-md;
  margin-top: 10px;
}

.colorPrimary {
  color: @primary-color;
}

.unread {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: @primary-color;
  position: absolute;
  right: @app-padding-lg;
  top: @app-padding-lg;
}

.jobAlertItem {
  border: solid 1px #ecedee;
  gap: @app-padding-md;
  margin-top: @app-padding-sm;

  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
}

.radioGroup {
  background-color: #f1f1f1;
  margin: 0 -20px -20px -20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: @app-padding-lg;
}

.manageAlertButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: @app-padding-lg;
}

.shareIcon {
  display: flex;
  justify-content: center;
  gap: @app-padding-sm;
  align-items: center;
  height: 10px;
}

.shareIconContainer {
  margin-top: @app-padding-sm;
}

.alertTitleLink:hover {
  color: black;
}
.alertTitleLink {
  color: black;
}

.profilename {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
  text-align: center !important;
}

.btnContainer {
  gap: @app-padding-lg !important;
}

.link {
  :global {
    a {
      color: @primary-color;
      text-decoration: underline;
    }
  }
}

.c360 {
  :global {
    .ant-popconfirm {
      .ant-popover-buttons {
        margin-left: 20px;
      }

      .ant-btn-sm {
        width: 80px !important;
      }

      //.ant-btn-primary {
      //  background-color: @danger-color !important;
      //  border-color: @danger-color !important;
      //}
    }
  }
}

.alertManagement {
  display: flex;
  flex-direction: column;
  gap: @app-gap-lg;
}

.bellIcon {
  font-size: 22px;
  color: @primary-color;
}

.emptyImage {
  max-width: 400px;
  margin-top: @app-padding-xxxl;

  @media (max-width: @screen-lg) {
    margin-top: @app-padding-xl;
  }
}

.tabContent {
  text-align: left;
}

.emptyContent {
  max-width: 580px;
}

.emptyContentButton {
  border-radius: 8px;
  margin-top: @app-padding-sm;
}

.emptyTextHeading {
  font-size: 28px;
  font-weight: bold;
}

.mainContent {
  > div {
    width: 100%;
  }
}

.jobCardButons {
  display: flex;
}

.jobCardButtonEmail {
  display: flex;
  @media (max-width: @screen-sm) {
    display: none;
  }
}

.deactivatecard {
  display: none;
  @media (min-width: @screen-sm) {
    display: flex;
  }
}
.jobCardButonsMobile {
  display: none;
  @media (max-width: @screen-sm) {
    display: flex;
  }
}

.jobCardButonsMobile:hover {
  > button {
    background-color: #050931;
    color: #ffffff !important;
    //border-color: #050931;
  }
}

.tabButtonMobile {
  margin: 0 5px;
}

.buttonBarMobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  flex-wrap: nowrap;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.buttonBarMobile::-webkit-scrollbar {
  display: none;
}

.homepageRow {
  // margin-top: 150px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 73%;

  // float: left;
  @media (max-width: @screen-sm-max) {
    flex-direction: column;
    margin-top: 40px;
  }
}

.homepageColumn {
  // margin-top: 150px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 73%;

  // float: left;
  @media (max-width: @screen-sm-max) {
    flex-direction: column;
    margin-top: 40px;
  }
}

.homepageRows {
  // margin-top: 150px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 73%;
  margin-bottom: -40px;

  // float: left;
  @media (max-width: @screen-sm-max) {
    flex-direction: column;
    margin-top: 40px;
  }
  @media (max-width: 820px) {
    margin-bottom: -10%;
  }
  @media (max-width: 765px) {
    width: 100%;
    margin-bottom: 0;
  }
}

.homepageRowContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.2;
  @media (max-width: 820px) {
    font-size: 42px;
  }
  @media (max-width: 540px) {
    font-size: 36px;
  }
  @media (max-width: 414px) {
    font-size: 28px;
  }
}

.partners {
  text-align: center;
  margin-bottom: -15px;
  @media (max-width: 765px) {
    padding-bottom: 50px;
  }
}

.Testimonials {
  margin-bottom: -15px;
  font-weight: bold;
  text-align: center;
  font-size: 48px;

  @media (max-width: 820px) {
    font-size: 42px;
  }
  @media (max-width: 540px) {
    font-size: 36px;
  }
  @media (max-width: 414px) {
    font-size: 28px;
  }
}

.cbTitle {
  font-size: 20px;
}

.greenText {
  color: @primary-color;
}

.blogpost {
  display: flex;
}

.blogpostimage {
  // float: right;
  padding-left: 4%;
  // padding-top: 87px;
  padding-top: 40px;
  width: 22%;

  @media (max-width: 920px) {
    padding-top: 125px;
  }
  @media (max-width: 820px) {
    padding-top: 113px;
  }
  @media (max-width: 766px) {
    padding-top: 153px;
  }
  @media (max-width: 540px) {
    padding-top: 140px;
  }
  @media (max-width: 422px) {
    padding-top: 121px;
  }
}

.recruiterRows {
  width: 100%;
  // max-width: 1300px;
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: @screen-lg-min) {
    max-width: 800px;
  }
  @media (max-width: @screen-sm-max) {
    flex-direction: column;
    margin-top: 40px;
  }
  @media (max-width: 765px) {
    width: 100%;
  }
}

.RowContainer {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (max-width: @screen-sm-max) {
    display: block;
  }

  .testimonialView {
    text-align: center;
    margin-top: 45px;
    margin-bottom: 40px;
    position: relative;
  }

  .btnPrimary {
    height: 65px;
    border-radius: 8px;

    @media (max-width: @screen-sm-max) {
      width: 100%;
    }
  }

  .btnText {
    padding: 0 74px;
    color: white;
    font-size: 28px;
    font-weight: bold;
  }

  .btnContainer {
    margin-top: 20px;
  }
}

.bestWeb {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 150px;
  height: 150px;
  @media (max-width: @screen-md-max) {
    width: 100px;
    height: 100px;
  }
}

.cvDownloadBtn {
  color: @primary-color;
  border: 1px solid #55bc7e;
  width: 201px;
  height: 43px;
}

.cvDownloadBtn:hover {
  background-color: @primary-color;
  color: white !important;
}
