@import '../../../styles/appvar';

.fluencySelectContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 17px;

  .fluencySelector {
    > div {
      max-height: 40px;

      @media (max-width: 380px) {
        max-height: none;
        width: 100%;
      }

      > label {
        max-height: 40px;
        text-align: center;
        line-height: 40px;
        height: 38px;
        padding: 0 23px;
        @media (max-width: 380px) {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .fluencyLanguage {
    font-size: 17px;
    font-weight: bold;
  }
}

.languageSelectRow {
}

.beniftbutton {
  border: 1px solid #e5e5e5;
  padding: 15px;

  :global{
    .ant-radio-group{
      flex-direction: column;
      display: flex;
      gap: 16px;
      font-size: 16px !important;
      margin-top: -16px;

      span{
        font-size: 16px !important;
      }
    }
  }
}

.beniftpad {
  padding-left: 10px;
}

.listnumborder {
  border: 1px solid #0e1f35;
  background-color: #0e1f35;
  border-radius: 5px;
  width: 25px;
  height: 23px;
  text-align: center;
  color: white;
  font-size: 15px;
  :global{
    p {
      margin: 0 4px;
    }
  }
}

.stickedListnumber {
  border: 1px solid #0e1f35;
  background-color: #0e1f35;
  width: 55px;
  height: 53px;
  text-align: center;
  color: white;
  font-size: 18px;
  margin-right: -12px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
}

.logoHolder {
  height: 178px;
  width: 178px;
  left: 11%;
  top: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  color: #ffff;
  border-radius: 50%;
  background: #fff;
  border: 2px dashed #e0dfdf;
  box-sizing: border-box;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.editButton {
  position: absolute;
  height: 42px;
  width: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #e0dfdf;
  box-sizing: border-box;
}

.editPositionAvatar {
  right: 400px;
  bottom: 5px;
}

.editIcon {
  color: #9d9d9f;
  font-size: 20px;
}

.dateRangeContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  max-height: 80px;
}

.schemaFormArrayRemove {
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  padding: 0 !important;
  position: absolute !important;
  right: -10px !important;
  top: -16px !important;
  border-radius: 10px !important;
  color: red !important;
  font-size: 24px !important;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease-in;
  z-index: 4;

  :hover {
    box-shadow: 0 0 4px red;
    border-radius: 15px;
  }
}

.approachContainer {
  .approachBtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .approachSwitchContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20px;
  }

  .approachText {
    margin-right: 20px;
  }

  .approachViaText {
    font-size: 14px;
    font-weight: bold;
  }
}

.eyeball {
  margin-top: 5px;
  justify-content: center;

  .eyeballText {
    margin-top: 5px;
    font-size: 14px;
  }
}

.experience {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.experienceTimeRange {
  display: flex;
  gap: 16px;
  width: 100%;

  > * {
    flex-grow: 1;
  }
}

.dateRangePicker {
  flex-grow: 1;
  display: block;
}

.langugeRow {
  display: flex;
  flex-direction: row;

  > div {
    @media (max-width: @screen-sm) {
      flex-direction: column;
    }
  }
}

.c360 {
  :global {
    .ant-popconfirm {
      .ant-popover-buttons {
        margin-left: 20px;
      }

      .ant-btn-sm {
        width: 80px !important;
      }

      //.ant-btn-primary {
      //  background-color: @danger-color !important;
      //  border-color: @danger-color !important;
      //}
    }
  }
}

.asterics {
  label:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}


.contact{
  :global {
    .ant-form-item{
      margin-inline-end: 0px !important;
    }
  }
}